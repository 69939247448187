'use strict';

var Intro = function() {
    var context = $('.intro');

    if (context) {
        var stageOne = $('.intro__stage__one');
        var stageTwo = $('.intro__stage__two');
        var btnOne = $('.intro__button--fwd');
        var btnTwo = $('.intro__button--back');

        var currentDate = new Date();
        var currentMonth = currentDate.getUTCMonth() + 1;
        var currentDay = currentDate.getUTCDate();
        var currentYear = currentDate.getUTCFullYear();
        var validDate = new Date( (currentYear - 14).toString() + '/' + currentMonth.toString() + '/' + currentDay.toString() );

        console.log('validDate', validDate);

        var validDay = false;
        var validMonth = false;
        var validYear = false;
        var isValidDate;

        var inputDay = $('.intro__input__day');
        var inputMonth = $('.intro__input__month');
        var inputYear = $('.intro__input__year');

        function checkButton() {
            if (validDay && validMonth && validYear) {
                btnOne.removeClass('-disabled');
            } else {
                btnOne.addClass('-disabled');
            }
        }

        function checkDate(year, month, day) {
            var inputDate = new Date(year + '/' + month + '/' + day);
            console.log('inpuitDate', inputDate);
            console.log('isValid?', inputDate >= validDate);

            if (inputDate <= validDate) {
                return isValidDate = true;
            } else {
                return isValidDate = false;
            }
        }

        checkButton();

        inputDay.on('change', function(){
            if (inputDay.val() > 0 && inputDay.val() <= 31 ) {
                validDay = true;
                checkButton();
            } else {
                alert('Ingresa un día válido');
            }
        });

        inputMonth.on('change', function(){
            if (inputMonth.val() > 0 && inputMonth.val() <= 12 ) {
                validMonth = true;
                checkButton();
            } else {
                alert('Ingresa un mes válido');
            }
        });

        inputYear.on('change', function(){
            if (inputYear.val() > 1900 && inputYear.val() <= currentYear ) {
                validYear = true;
                checkButton();
            } else {
                alert('Ingresa un año válido');
            }
        });

        btnOne.on('click', function() {

            checkDate(inputYear.val(), inputMonth.val(), inputDay.val());

            if ( !isValidDate ) {
                stageOne.removeClass('-visible');
                stageTwo.addClass('-visible');
            } else {
                window.location.replace(window.location.href + '/landing');
            }
        });

        btnTwo.on('click', function() {
            stageTwo.removeClass('-visible');
            stageOne.addClass('-visible');
        });


    }
}

module.exports = Intro;
